import {
    mapState,
} from 'vuex'

const myPlugin = {
    install(Vue) {
        Vue.mixin({
            data: () => ({
                AccessKey: process.env.VUE_APP_ACCESS_KEY,
                SecretKey: process.env.VUE_APP_SECRET_KEY,
                api: process.env.VUE_APP_URL,
                aws: process.env.VUE_APP_URL_AWS,
                apiKey: {
                    headers: {
                      "Content-Type": "application/json",
                      "x-api-key": process.env.VUE_APP_API_KEY,
                    },
                  },
                aws2:process.env.VUE_APP_PLANNET_URL_AWS,
                apiKey2: {
                    headers: {
                      "Content-Type": "application/json",
                      "x-api-key": process.env.VUE_APP_PLANNET_API_KEY,
                    }
                  },
                  gcAWS:process.env.VUE_APP_GC_API,
                  gcAPI_Key: {
                    headers: {
                      "Content-Type": "application/json",
                      "x-api-key": process.env.VUE_APP_GC_API_KEY,
                    }
                  },
                  DSS_api:process.env.VUE_APP_DSS_API,
                  DSS_apiKey: {
                    headers: {
                      "Content-Type": "application/json",
                      "x-api-key": process.env.VUE_APP_DSS_API_KEY,
                    }
                  },
                windowSize: {
                    x: 0,
                    y: 0
                },
                deployment: 'test',
                deploy: process.env.DEPLOYMENT1
            }),
            methods: {
                onResize() {

                    this.windowSize = { x: window.innerWidth, y: window.innerHeight }

                },
            },
            computed: {
                ...mapState([
                     'userInfo',
                ])
            }
        })
    }
}
    export default myPlugin