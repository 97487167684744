import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en'
import ja from './locales/ja'
 
Vue.use(VueI18n)

export default new VueI18n({
    locale: 'ja',
    fallbackLocale: 'en',
    messages: {
        en ,ja
    }
  })