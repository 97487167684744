<template>
  <v-dialog v-model="dialog">
    <v-card height="auto">
        <v-toolbar dense color="primary" dark>
          <v-icon size="20px">mdi-format-list-bulleted</v-icon>
          <span class="pl-2">{{$t(`details.Detail`)}}</span>
          <v-spacer/>
          <v-icon @click="closeDialog()" color="red" medium>mdi-close</v-icon>
        </v-toolbar>
    </v-card>
    <v-card class="pa-5">
      <!-- CADRequest -->
      <v-simple-table fixed-header :height="height" dense v-if="dialogData == 'CADRequest'" class="pb-7">
          <thead>
            <tr>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.CustomerCode`)}}</th>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.CADPhase1`)}}</th>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.CADPDR`)}}</th>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.CADFinal`)}}</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr v-for="(item,i) in newCADdata" :key="i" :style="{ 'background-color': i === newCADdata.length - 1 ? '#FFD700' : '' }"> -->
            <tr v-for="(item,i) in newCADdata" :key="i">
              <td class="text-center TableStyle">{{item.ConstructionCode}}</td>
              <td class="text-center TableStyle">{{item.CadPhase1 != null ? item.CadPhase1 : '―'}}</td>
              <td class="text-center TableStyle">{{item.CadPdr != 0 ? item.CadPdr != null ? item.CadPdr : '―' : item.CadDenkiPlan != null ? item.CadDenkiPlan : '―'}}</td>
              <td class="text-center TableStyle">{{item.CadFinal != null ? item.CadFinal : '―'}}</td>
            </tr>
          </tbody>
        </v-simple-table>
      <!-- CADRequest -->

      <!-- CadPlanners -->
      <v-simple-table fixed-header :height="height" dense v-else-if="dialogData == 'CadSalesOffice'">
          <thead>
            <tr>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.SalesOffice`)}}</th>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.AveCADPhase1`)}}</th>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.AveCADPDR`)}}</th>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.AveCADFinal`)}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in newCADdata" :key="i" :style="{ 'background-color': i === 0 ? '#FFD700' : '' }">
              <td class="text-center TableStyle">{{item.SalesOfficeName}}</td>
              <td class="text-center TableStyle">{{item.CadPhase1}}</td>
              <td class="text-center TableStyle">{{item.CadPdr}}</td>
              <td class="text-center TableStyle">{{item.CadFinal}}</td>
            </tr>
          </tbody>
        </v-simple-table>
      <!-- CadPlanners -->

      <!-- CadSalesOffice -->
        <v-simple-table fixed-header :height="height" dense v-else-if="dialogData == 'CadPlanners'">
          <thead>
            <tr>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.SalesOffice`)}}</th>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.ExhibitionHall`)}}</th>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.Planner`)}}</th>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.AveCADPhase1`)}}</th>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.AveCADPDR`)}}</th>
              <th class="text-center TableStyle pa-0" style="width:90px; background-color: #01579b; color: white">{{$t(`details.AveCADFinal`)}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in newCADdata" :key="i">
              <td class="text-center TableStyle">{{item.SalesOfficeName}}</td>
              <td class="text-center TableStyle">{{item.BranchName}}</td>
              <td class="text-center TableStyle">{{item.EmployeeName}}</td>
              <td class="text-center TableStyle">{{item.CadPhase1}}</td>
              <td class="text-center TableStyle">{{item.CadPdr}}</td>
              <td class="text-center TableStyle">{{item.CadFinal}}</td>
            </tr>
          </tbody>
        </v-simple-table>
      <!-- CadSalesOffice -->
    </v-card>
  </v-dialog> 
</template>

<script>
export default {
  props: {
    dialogData: String,
    CADdata: Array,
  },
  data() {
    return {
      dialog: false,
      height: window.innerHeight -270,
      newCADdata: [],
      newCADRequest: {
        'ConstructionCode': '平均',
        'CadPhase1': '',
        'CadPdr': '',
        'CadFinal': '',
      },
      newCADSalesOffice: {
        'SalesOfficeName': '全国平均',
        'CadPhase1': '',
        'CadPdr': '',
        'CadFinal': '',
      }
    };
  },
  methods: {
    openDialog() {
      // console.log(this.CADdata,'CADdata');
      this.newCADdata = JSON.parse(JSON.stringify(this.CADdata));
      if (this.dialogData === 'CADRequest') {
        if (!this.isObjInCADdata(this.newCADRequest)) {

            const cadPhase1Values = this.newCADdata.map(data => data.CadPhase1).filter(value => value > 0);
            const totalCadPhase1 = cadPhase1Values.reduce((sum, value) => sum + value, 0);
            const averageCadPhase1 = (totalCadPhase1 / cadPhase1Values.length).toFixed(2);

            const cadPdrValues = this.newCADdata.map(data => data.CadPdr).filter(value => value > 0);
            const totalCadPdr = cadPdrValues.reduce((sum, value) => sum + value, 0);
            const averageCadPdr = (totalCadPdr / cadPdrValues.length).toFixed(2);
            
            const cadFinalPlanValues = this.newCADdata.map(data => data.CadFinal).filter(value => value > 0);
            const totalCadFinalPlan = cadFinalPlanValues.reduce((sum, value) => sum + value, 0);
            const averageCadFinalPlan = (totalCadFinalPlan / cadFinalPlanValues.length).toFixed(2);

          this.newCADdata.push({
            'ConstructionCode': '平均',
            'CadPhase1': isNaN(averageCadPhase1) ? 0 : averageCadPhase1,
            'CadPhase1Total': totalCadPhase1,
            'CadPdr': isNaN(averageCadPdr) ? 0 : averageCadPdr,
            'CadPdrTotal': totalCadPdr,
            'CadFinal': isNaN(averageCadFinalPlan) ? 0 : averageCadFinalPlan,
            'CadFinalPlanTotal': totalCadFinalPlan,
          });
        }
      }else if(this.dialogData === 'CadSalesOffice'){
        if (!this.isObjInCADdata(this.newCADSalesOffice)) {

          const cadPhase1Values = this.newCADdata.map(data => data.CadPhase1).filter(value => value > 0);
          const totalCadPhase1 = cadPhase1Values.reduce((sum, value) => sum + value, 0);
          const averageCadPhase1 = (totalCadPhase1 / cadPhase1Values.length).toFixed(2);

          const cadPdrValues = this.newCADdata.map(data => data.CadPdr == 0 ? data.CadDenkiPlan : data.CadPdr).filter(value => value > 0);
          const totalCadPdr = cadPdrValues.reduce((sum, value) => sum + value, 0);
          const averageCadPdr = (totalCadPdr / cadPdrValues.length).toFixed(2);
          
          const cadFinalPlanValues = this.newCADdata.map(data => data.CadFinal).filter(value => value > 0);
          const totalCadFinalPlan = cadFinalPlanValues.reduce((sum, value) => sum + value, 0);
          const averageCadFinalPlan = (totalCadFinalPlan / cadFinalPlanValues.length).toFixed(2);

          this.newCADdata.unshift({
            'SalesOfficeName': '全国平均',
            'CadPhase1': isNaN(averageCadPhase1) ? 0 : averageCadPhase1,
            'CadPhase1Total': totalCadPhase1,
            'CadPdr': isNaN(averageCadPdr) ? 0 : averageCadPdr,
            'CadPdrTotal': totalCadPdr,
            'CadFinal': isNaN(averageCadFinalPlan) ? 0 : averageCadFinalPlan,
            'CadFinalPlanTotal': totalCadFinalPlan,
          });
        }
      }else{
        this.newCADdata.forEach((item) => {
          const employeeNameParts = item.EmployeeName.split('　');
          if (employeeNameParts.length > 0) {
            item.EmployeeName = employeeNameParts[0];
          }
        });
      }
    // console.log(this.newCADdata)
      this.dialog = true;
    },
    isObjInCADdata(obj) {
      return this.newCADdata.some((item) => {
        if (this.dialogData === 'CADRequest'){
          return item.ConstructionCode === obj.ConstructionCode;
        }else if (this.dialogData === 'CadSalesOffice'){
          return item.SalesOfficeName === obj.SalesOfficeName;
        }else{
          return item
        }
      });
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style>
.TableStyle {
  border: 1px solid black;
  white-space: nowrap;
}
.fixed-row {
  position: absolute;
  bottom: 0;
  background-color: white;
  z-index: 1;
  padding-bottom: 1rem;
  min-width: '90px';
}
</style>
