import AWS from 'aws-sdk'
export default class UserManual{
    constructor(){


  
    }
    
    getUserManual(obj,location,file){
        const key = `${location}/${file}`;
        const bucketName = 'ichijosekkei';
        const S3Config = new AWS.S3({
            accessKeyId: obj.AccessKey,
            secretAccessKey: obj.SecretKey,
            region: 'ap-southeast-1'
          });

         
          const downloadParams = {
                Bucket: bucketName,
                Key: key,
                ResponseContentDisposition:  `attachment; filename="${file}"`
            
            };
          
            S3Config.getSignedUrl("getObject", downloadParams, (err, data) => {
                if (err) console.log(err);
                window.open(data,'_blank')
            });
    }
}

