export default class DataTransform{
  constructor(){
  }
  
  toEncrypt(data){
    if (Array.isArray(data)) {
      return data.map(item => this.encryptItem(item));
    } else {
      return this.encryptItem(data);
    }
  }

  toDecrypt(data){
    if (Array.isArray(data)) {
      return data.map(item => this.decryptItem(item));
    } else {
      return this.decryptItem(data);
    }
  }
  
  encryptItem(item) {
    const jsonString = JSON.stringify(item);
    const encoder = new TextEncoder();
    const encodedBytes = encoder.encode(jsonString);
    const encryptedText = btoa(String.fromCharCode(...encodedBytes));
    return encryptedText;
  }
  decryptItem(item) {
    const decodedBytes = atob(item);
    const decoder = new TextDecoder();
    const decodedString = decoder.decode(Uint8Array.from(decodedBytes, c => c.charCodeAt(0)));
    const decryptedText = JSON.parse(decodedString);
    return decryptedText
  }
}

