import axios from 'axios';
import moment from 'moment';

export default class ReplicationStatus{
  constructor() {
    this.update = {
      TH: '',
      CAD: ''
    }
  }
  getStatus(aws,apiKey) {
    axios.get(`${aws}getLatestUpload`, apiKey)
      .then(res => {
        this.update.CAD = moment(res.data[0].created_date).utc().locale('ja').format('LL');
      });

    axios.get(`${aws}getLatestUploadPlanet`, apiKey)
      .then(res => {
        this.update.TH = moment(res.data[0].SentProcessRequestDate).utc().locale('ja').format('LL');
      });
  }
}
