import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins:[createPersistedState({storage: window.sessionStorage})],
  state: {
    userInfo:null,
    chartUrl: null,
    salesOfficeStorage: null,
  },
  mutations: {
    STORE_CHART_URL(state, payload){
      state.chartUrl = payload
    },
    'STORE_USERINFO':(state, newState)=>{
      state.userInfo = newState
    },
    'STORE_SALESOFFICE':(state, newState) => {
      state.salesOfficeStorage = newState
    },
  },
  actions: {

  },
  modules: {
  }
})


