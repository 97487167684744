import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './vue-i18n'
import './registerServiceWorker'
import myMixin from './plugins/myMixins'  
import vuetify from './plugins/vuetify'
import JsonExcel from "vue-json-excel";
import VueApexCharts from 'vue-apexcharts'
import DetailsDialog from './components/Details.vue';
import DataTransform from './assets/class/DataTransform.js'
import Swal from "sweetalert2";
import axios from 'axios'
Vue.config.productionTip = false
Vue.use(myMixin)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component("downloadExcel", JsonExcel);
Vue.component('details-dialog', DetailsDialog);
Vue.prototype.$url = window.location.origin;
Vue.prototype.$dataTransform = new DataTransform();
(Vue.prototype.$URL = "http://10.169.140.17:4567/"), 
new Vue({
  i18n,
  router,
  store,
  vuetify,
  data() {
    return {
      decryptedUser: null,
      decryptedSalesOffices: null
    }
  },
  render: h => h(App),
  created() {
    this.setupInactivityTimer();
  },
  methods: {
    setupInactivityTimer() {
      let timer;
      let duration = 120 * 60 * 1000 //2 hours
      const resetTimer = () => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          if(this.$route.fullPath != '/'){
            this.logout()
          }
        }, duration);
      }

      window.addEventListener('mousemove', resetTimer)
      window.addEventListener('keydown', resetTimer)
      resetTimer()
    },
    async getAllSalesOffice(){
      try {
        // if(!this.$store.state.salesOfficeStorage){
          const { data } = await axios.get(`${this.aws}/getSalesOffices`, this.apiKey);
          const results = data.map(({ SalesOfficeCode, SalesOfficeName, EmployeeCode, EmployeeName, ContractExhibitionName, sort }) => ({
              SalesOfficeCode,
              SalesOfficeName,
              EmployeeCode,
              EmployeeName,
              ContractExhibitionName,
              sort
          }));
          this.decryptedSalesOffices = results
          this.$store.commit("STORE_SALESOFFICE", this.$dataTransform.toEncrypt(results))
        // }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: error.message,
        });
      }
    },
    logout() {
      this.$store.commit("STORE_USERINFO", null);
      this.$store.commit("STORE_SALESOFFICE", null)
      this.$store.commit("STORE_CHART_URL", null);
      this.$router.push("/login");
    },
    decryptAndSetUserInfo() {
      this.decryptedUser = this.$dataTransform.toDecrypt(this.$store.state.userInfo);
    }
  },
  watch: {
    userInfo: {
      handler(newUserInfo) {
        if (newUserInfo !== null) {
          this.decryptAndSetUserInfo()
          this.getAllSalesOffice()
        }
      },
      immediate: true,
    },
  }
}).$mount('#app')
