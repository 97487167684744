import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from "../store";


Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: { name: 'Home' }
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  }
  ,
  {
    path: '/personaldata',
    name: 'PersonalData',
    component: () => import('../views/personalData.vue')
  }
  ,
  {
    path: '/cadrequest',
    name: 'CadRequest',
    component: () => import('../views/cadRequest.vue')
  }
  ,
  {
    path: '/plannerjobdate',
    name: 'PlannerJobDate',
    component: () => import('../views/plannerJobDate.vue')
  },
  {
    path: '/overalldata',
    name: 'overalldata',
    component: () => import('../views/OverallDataPage.vue')
  },
  {
    path: '/salesoffice',
    name: 'salesoffice',
    component: () => import('../views/SalesOfficePage.vue')
  },
  {
    path: '/master',
    name: 'mastermaintenance',
    component: () => import('../views/masterMaintenance.vue')
  },
  {
    path: '/plannerranking',
    name: 'plannerranking',
    component: () => import('../views/PlannerRanking.vue')
  },
  // {
  //   path: '/salesofficeranking',
  //   name: 'salesofficeranking',
  //   component: () => import('../views/SalesOfficeRanking.vue')
  // },
  {
    path: '/linkHRD',
    name: 'HRDSchedulePage',
    component: () => import('../views/LinkToHRDSchedule.vue')
  },
  {
    path: '/linkCAD',
    name: 'CADRequestPage',
    component: () => import('../views/LinkToCadRequest.vue')
  },
  {
    path: '/linkDSS',
    name: 'DSSPage',
    component: () => import('../views/LinkToDSS.vue')
  },
  {
    path: '/linkHRDInquiry',
    name: 'HRDInquiry',
    component: () => import('../views/LinkToHRDInquiry.vue')
  }
  ,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    if (store.state.userInfo != null) {
      next('/')
    }
    else { next() }
  } else {
    if (store.state.userInfo != null) {
      next()
    } else {  
      next('/login')
    }
  }
});



export default router
